<!-- 控制名字/高精显示与否 -->
<template>
  <div class="tool-box">
    <div
      class="tool-item"
      :class="{
        'tool-item-active': $store.state.StationManagement.isShowLabel,
      }"
      @click="nameClick"
    >
      名称
    </div>
    <div
      class="tool-item"
      v-if="!isIOS"
      :class="{
        'tool-item-active': $store.state.StationManagement.isShowHighPrecision,
      }"
      @click="highPrecisionClick"
    >
      高精
    </div>
  </div>
</template>
<script>
import { isIOS } from "@/utils/index";
export default {
  name: "StationManagementTool",
  data() {
    return {
      isIOS: isIOS(),
    };
  },
  computed: {},
  mounted() {},
  methods: {
    nameClick() {
      this.$store.commit(
        "updateisShowLabel",
        !this.$store.state.StationManagement.isShowLabel
      );
    },
    highPrecisionClick() {
      this.$store.commit(
        "updateisShowHighPrecision",
        !this.$store.state.StationManagement.isShowHighPrecision
      );
    },
  },
};
</script>

<style scoped lang="scss">
.tool-box {
  position: absolute;
  left: 0.3rem;
  bottom: 0.3rem;
  .tool-item {
    width: 0.64rem;
    height: 0.64rem;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    font-size: 0.25rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    // transition: all 0.3s ease;
    & + .tool-item {
      margin-top: 0.36rem;
    }
    &.tool-item-active {
      background: rgba(67, 110, 255, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>
