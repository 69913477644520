/**
 * POI采集弹窗
 * @ prop data { 
  * isShow: 弹窗展示, 
* }
*/
<template>
  <van-action-sheet v-model="show" title="请选择POI类型" :closeable="false">
    <div class="content">
      <van-button
        plain
        type="primary"
        class="m_b_12"
        @click="collectGeneralPOIClick"
        >普通POI</van-button
      >
      <van-button
        plain
        type="primary"
        class="m_b_20"
        @click="collectHighPrecisionPOIClick"
        >高精POI</van-button
      >
      <van-button type="default" class="cancel-btn" @click="cancelClick"
        >取消</van-button
      >
    </div>
  </van-action-sheet>
</template>

<script>

export default {
  props: ["isShow"],
  data() {
    return {

    };
  },
  computed: {
    show: {
      get: function () {
        return this.isShow || false
      },
      set: function (val) {
        this.$emit("update:isShow");
      }
    }
  },
  created() {
  },

  mounted() { },

  methods: {
    collectGeneralPOIClick() {
      this.$emit("onCollectGeneralPOI");
    },
    collectHighPrecisionPOIClick() {
      this.$emit("onCollectHighPrecisionPOI");
    },
    cancelClick() {
      this.$emit("update:isShow", false);
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  padding: 20px 40px 24px 40px;
  .m_b_12 {
    margin-bottom: 12px;
  }
  .m_b_20 {
    margin-bottom: 20px;
  }
  :deep(.van-action-sheet__header) {
    line-height: 55px;
    font-weight: bold;
  }
  :deep(.van-button) {
    font-size: 16px;
    font-weight: bold;
    height: 60px;
    line-height: 60px;
    &::before {
      background: transparent;
    }
  }
  :deep(.van-button--primary) {
    border: 1px solid #436eff;
    color: #436eff;
  }
  .cancel-btn {
    border-radius: 23px;
    background: #ecedf2;
    border: 0;
    font-size: 14px;
    font-weight: bold;
    height: 46px;
    line-height: 46px;
  }
}
</style>
