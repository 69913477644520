<!-- 高精图层 -->
<template></template>
<script>
import { mapGetters } from "vuex";
import env from "../../../env";
const highPrecisionLayerUrl = `${env.API_HighPrecisionLayer}`;
let vehicleLaneBoundaryLayer, // 车道边界线图层
  vehicleLaneLayer, // 非机动车道
  vehicleLaneDriverLayer; // 机动车道

const dataZooms = [6, 21];
export default {
  name: "HighPrecision",
  props: ["map"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isShowHighPrecision: "getisShowHighPrecision",
    }),
  },
  watch: {
    isShowHighPrecision: {
      handler: function () {
        if (this.isShowHighPrecision) {
          if (vehicleLaneBoundaryLayer) {
            vehicleLaneBoundaryLayer.show();
            vehicleLaneLayer.show();
            vehicleLaneDriverLayer.show();
          } else {
            this.createHighPrecisionLayer();
          }
        } else {
          vehicleLaneBoundaryLayer && vehicleLaneBoundaryLayer.hide();
          vehicleLaneLayer && vehicleLaneLayer.hide();
          vehicleLaneDriverLayer && vehicleLaneDriverLayer.hide();
        }
      },
    },
  },
  mounted() { },
  methods: {
    createHighPrecisionLayer() {
      // console.log(this.map)
      // if (!this.map) return
      // 非机动车道
      vehicleLaneLayer = new AMap.MapboxVectorTileLayer({
        zIndex: 1,
        opacity: 1,
        url: `${highPrecisionLayerUrl}?x=[x]&y=[y]&z=[z]&type=vehicleLane&laneType=nonMotor`,
        dataZooms,
        zooms: dataZooms,
        tileSize: 256,
        styles: {
          line: {
            sourceLayer: "data",
            color: "rgba(255, 234, 163, 1)",
            lineWidth: 2,
            // dash: [0.7, 0.7,  20,0.7],
          },
        },
      });
      this.$parent.$options.map.add(vehicleLaneLayer);

      // 机动车道
      vehicleLaneDriverLayer = new AMap.MapboxVectorTileLayer({
        zIndex: 1,
        opacity: 1,
        url: `${highPrecisionLayerUrl}?x=[x]&y=[y]&z=[z]&type=vehicleLane&laneType=motorDriven`,
        dataZooms,
        zooms: dataZooms,
        tileSize: 256,
        styles: {
          line: {
            sourceLayer: "data",
            color: "rgba(166, 191, 255, 1)",
            lineWidth: 2,
            // dash: [0.7, 0.7,  20,0.7],
          },
        },
      });
      this.$parent.$options.map.add(vehicleLaneDriverLayer);

      // 车道边界线图层
      vehicleLaneBoundaryLayer = new AMap.MapboxVectorTileLayer({
        zIndex: 1,
        opacity: 1,
        url: `${highPrecisionLayerUrl}?x=[x]&y=[y]&z=[z]&type=vehicleLaneBoundary`,
        dataZooms,
        zooms: dataZooms,
        tileSize: 256,
        styles: {
          line: {
            sourceLayer: "data",
            color: "#148C28",
            lineWidth: 1,
            // dash: [0.7, 0.7,  20,0.7],
          },
        },
      });
      this.$parent.$options.map.add(vehicleLaneBoundaryLayer);
      // vehicleLaneLayer.on('click', (e) => {
      //   console.log(e)
      // })
    },
  },
};
</script>

<style scoped lang="scss"></style>
