<!-- POI搜索 -->
<template>
  <div>
    <div class="searchPanel">
      <div class="searchBar">
        <div
          class="searchBarSpace"
          :style="{ height: `${appBarHeight}rem` }"
        ></div>
        <div class="searchBarInner">
          <div class="inputWrapper">
            <img
              class="searchIcon"
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/search.png"
              alt=""
            />
            <input
              ref="ipt"
              type="text"
              v-model="value"
              maxlength="20"
              :placeholder="inputPlaceholder"
            />
            <img
              v-if="showClear"
              @click="clrearValueClick"
              class="clearIcon"
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/clear.png"
              alt=""
            />
          </div>
          <div class="cancelBtn" @click="handleCancel">取消</div>
        </div>
      </div>
      <div class="searchResult" :style="{ paddingTop: `${appBarHeight}rem` }">
        <van-list v-if="filterList.length" class="list">
          <van-swipe-cell
            v-for="(item, index) in filterList"
            :key="item.id"
            :name="index"
            :ref="'ref' + index"
            @open="open"
          >
            <div class="listItem" @click="handleListItemClick(item)">
              <div class="poi-info">
                <div class="poi-name" v-html="poiName(item)"></div>
                <div class="park-name">{{ item.parkName }}</div>
              </div>
              <div v-if="item.poiType" class="poi-type">
                {{ poiType(item) }}
              </div>
            </div>
            <template #right>
              <div class="deleteBtn" @click="handleDeleteClick(item)">删除</div>
            </template>
          </van-swipe-cell>
        </van-list>
        <div
          class="noData"
          v-if="filterList.length === 0 && !loading"
          :style="{ paddingTop: `${appBarHeight + 2.3}rem` }"
        >
          <img
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/no_data.png"
            alt=""
          />
          <span>暂无数据</span>
        </div>
      </div>
    </div>
    <Dialog
      v-if="showDialog"
      dialogContent="确认删除该站点吗？"
      @handleOk="handleDialogOk"
      @handleCancel="handleDialogCancel"
    >
    </Dialog>
  </div>
</template>

<script>
import { searchNearestStationList, deletePoi } from '@/api/apiv2';
import { debounce } from '@/utils/index';
import { POIType } from '@/constant';
import Dialog from '@/components/Dialog.vue';

export default {
  components: { Dialog },
  props: {
    coordinate: {
      type: Array,
      default: [],
    },
    parkInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    showClear() {
      return this.value !== '';
    },
    inputPlaceholder() {
      if (this.parkInfo) {
        let parkName = this.parkInfo.parkName;
        if (parkName.length > 10) {
          parkName = `${parkName.slice(0, 10)}...`;
        }
        return `搜索${parkName}的POI`;
      }
      return '请输入POI名称';
    },
  },
  data() {
    return {
      value: '',
      filterList: [], // 筛选列表
      historyList: [], // 搜索历史
      noData: false,
      loading: true,
      currentOpen: null,
      showDialog: false,
      appBarHeight: `${
        (sessionStorage.getItem('_appBarHeight') ?? 0) / 100 + 0.1
      }`,
    };
  },
  mounted() {
    this.$refs.ipt.focus();
    // 使用防抖调后端联想查询
    this.$refs.ipt.addEventListener(
      'input',
      debounce(() => {
        if (this.value.length > 1 || this.value.length === 0) {
          this.getPOIList();
        }
      }, 500)
    );
    this.$loadingCircle.start();
    this.getPOIList();
  },
  methods: {
    clrearValueClick() {
      this.value = '';
      this.filterList = [];
      this.getPOIList();
    },
    handleCancel() {
      this.value = '';
      this.$emit('onCancel');
    },
    handleListItemClick(item) {
      this.value = '';
      this.$emit('onSelect', item);
    },
    handleDeleteClick(item) {
      this.currentStation = item;
      this.showDialog = true;
    },
    handleDialogOk() {
      this.showDialog = false;
      this.deleteStation();
    },
    handleDialogCancel() {
      this.showDialog = false;
    },
    getPOIList() {
      let param = {
        stationName: this.value,
      };
      if (this.parkInfo) {
        param.parkCode = this.parkInfo.parkCode;
      } else if (this.coordinate.length > 0) {
        param.lng = this.coordinate[0];
        param.lat = this.coordinate[1];
      }
      searchNearestStationList(param)
        .then((res) => {
          this.filterList = res;
        })
        .finally(() => {
          this.$loadingCircle.end();
        });
    },
    // 删除站点并重新更新
    async deleteStation() {
      this.currentOpen = null;
      const res = await deletePoi({ id: this.currentStation.id });
      if (res) {
        this.filterList = this.filterList.filter(
          (e) => e.id !== this.currentStation.id
        );
      }
    },
    open(e) {
      if (this.currentOpen && this.currentOpen._props.name !== e.name) {
        // 关闭上一个cell,连续滑动同一个cell时不用调close
        this.currentOpen.close();
      }
      this.currentOpen = this.$refs['ref' + e.name][0];
    },
    poiName(item) {
      if (this.value) {
        let str = this.value;
        let replaceName = `<span class='hight-keyword'>${str}</span>`;
        var reg = new RegExp(str, 'g');
        let result = item.name.replace(reg, replaceName);
        return result;
      } else {
        return item.name;
      }
    },
    poiType(item) {
      const type = POIType[item.poiType];
      return type?.name || '';
    },
  },
};
</script>

<style lang="scss" scoped>
.searchPanel {
  width: 100vw;
  // min-height: 100vh;

  .searchBar {
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: 1;

    .searchBarSpace {
      background: white;
    }
    .searchBarInner {
      padding: 0.16rem 0.16rem 0.26rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .inputWrapper {
      flex-grow: 1;
      width: 6.34rem;
      height: 0.64rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: #f6f7f8;
      border-radius: 0.08rem;

      .searchIcon {
        width: 0.28rem;
        height: 0.28rem;
        margin-left: 0.18rem;
        margin-right: 0.16rem;
      }

      input {
        height: 0.64rem;
        flex-grow: 1;
        font-size: 0.32rem;
        color: #000000;
        caret-color: #436eff;
        background: #f6f7f8;
        padding-right: 25px;
        &::placeholder {
          font-size: 0.28rem;
          color: #bfc0cb;
          font-weight: normal;
        }
      }

      .clearIcon {
        width: 0.36rem;
        height: 0.36rem;
        position: absolute;
        top: 0.14rem;
        right: 0.16rem;
      }
    }

    .cancelBtn {
      line-height: 0.64rem;
      margin-left: 0.2rem;
      font-size: 0.32rem;
      color: #000000;
      height: 0.64rem;
      width: max-content;
    }
  }

  .searchResult {
    background: white;
    margin-top: calc(0.64rem + 0.26rem + 0.16rem);

    .list {
      .listItem {
        background: white;
        box-sizing: border-box;
        font-size: 0.32rem;
        color: #000000;
        overflow: hidden;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 20px 8px 0;
        margin-left: 20px;
        border-bottom: 1px solid #ebebeb;
        &:last-child {
          border-bottom: 0;
        }
        .poi-info {
          width: calc(100% - 40px);
          .poi-name {
            font-size: 16px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .park-name {
            font-size: 14px;
            font-weight: 400;
            color: rgba(151, 152, 171, 1);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .poi-type {
          width: 48px;
          height: 22px;
          border-radius: 12px;
          background: #eff1f3;
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: rgba(151, 152, 171, 1);
        }
      }
    }

    .noData {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #f5f5f5;

      img {
        width: 3.2rem;
        height: 3.2rem;
      }

      span {
        font-size: 0.32rem;
        color: #222222;
      }
    }
  }
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
  .deleteBtn {
    font-size: 0.36rem;
    line-height: 1.16rem;
    color: #ffffff;
    width: 1.55rem;
    height: 1.16rem;
    background: #fa4b3e;
    text-align: center;
  }
}
.initialLoading {
  position: absolute;
  top: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  img {
    width: 0.75rem;
    height: 0.75rem;
    animation: rotating 0.8s linear infinite;
  }
}
</style>
