export const POIType = {
  1: {
    key: "NETWORK",
    name: "网点",
  },
  2: {
    key: "JOINT",
    name: "接驳点",
  },
  3: {
    key: "RISK",
    name: "风险点",
  },
  4: {
    key: "TAKE_OVER",
    name: "接管点",
  },
  5: {
    key: "LOADING",
    name: "装车点",
  },
  6: {
    key: "STOP",
    name: "停车点",
  },
  7: {
    key: "START",
    name: "发车点",
  },
  8: {
    key: "UNLOAD",
    name: "卸车点",
  },
};
